import i1 from '../../assets/images/i1.svg'
import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'

export const Break = ({
  id,
  available_at,
}: {
  id: string
  available_at: string
}) => {
  const [time, setTime] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      //@ts-ignore
      setTime(convertMsToHMS(Math.abs(new Date() - new Date(available_at))))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const navigate = useNavigate()

  return (
    <div className="outroPageGrid">
      <div className="imgHeader">
        <img src={i1} alt="" />
      </div>
      <div className="outroContent">
        <div className="outroTextBox">
          <h4 className="mt-4">Kolejny kwestionariusz rozpocznie się za:</h4>
          {time}
          <p>
            Kolejny kwestionariusz zajmuje średnio:
            <strong>15 minut</strong>. Jest <strong>krótki</strong>, bo zawiera
            <strong> 55 pytań</strong>.
          </p>
          <div className="countdown">
            <svg viewBox="-50 -50 100 100" stroke-width="10">
              <circle r="45"></circle>
              <circle
                r="45"
                stroke-dasharray="282.7433388230814"
                stroke-dashoffset="282.7433388230814px"
              ></circle>
            </svg>
          </div>
        </div>
        <div className="d-flex justify-content-center">
          <button
            className="btn btnPrimary btnMedium me-3"
            onClick={() => navigate(`/manuals/${id}`)}
          >
            <span>Rozpocznij teraz</span>
          </button>
          <button
            className="btn btnSecondary btnMedium"
            onClick={() => navigate('/studies')}
          >
            Wróć do listy
          </button>
        </div>
      </div>
    </div>
  )
}
function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

function convertMsToHMS(milliseconds: number) {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  let hours = Math.floor(minutes / 60)

  seconds = seconds % 60
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes

  minutes = minutes % 60

  // 👇️ If you don't want to roll hours over, e.g. 24 to 00
  // 👇️ comment (or remove) the line below
  // commenting next line gets you `24:00:00` instead of `00:00:00`
  // or `36:15:31` instead of `12:15:31`, etc.
  hours = hours % 24

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`
}
