import { useMutation, useQuery } from 'react-query'
import { PaginatedDTOWrapper } from '../subjects/dto'
import {
  getSetStudiesAsFilled,
  getSingleStudyForSubject,
  getStudiesForSubject,
} from './calls'
import { SingleStudy, Study } from './models'

export const useStudiesForSubject = () => {
  return useQuery<PaginatedDTOWrapper<Study>>(
    'studies-for-subject',
    getStudiesForSubject
  )
}
export const useSingleStudyForSubject = (id: string) => {
  return useQuery<SingleStudy[]>('single-studie-for-subject', () =>
    getSingleStudyForSubject(id)
  )
}

export const useSetStudiesAsFilledMutation = () => {
  return useMutation(getSetStudiesAsFilled)
}
