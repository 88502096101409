import { useState } from 'react'
import { useNavigate } from 'react-router'
import { usersTokenObtain } from '../api/users/calls'
import { useAuth } from '../context/AuthProvider'

export const Login = ({ setUser }: any) => {
  const [userLogin, setUserLogin] = useState('')
  const [password, setPassword] = useState('')
  const { login } = useAuth()

  const navigate = useNavigate()

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    const data = await usersTokenObtain({ login: userLogin, password })
    login(data);
  }

  return (
    <section className="section">
      <form className="form" onSubmit={handleSubmit}>
        <h5>login</h5>
        <div className="form-row">
          <label htmlFor="name" className="form-label">
            login
          </label>
          <input
            type="text"
            className="form-input"
            id="name"
            value={userLogin}
            onChange={(event) => setUserLogin(event.target.value)}
          />
        </div>
        <div className="form-row">
          <label htmlFor="email" className="form-label">
            password
          </label>
          <input
            type="password"
            className="form-input"
            id="email"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>
        <button type="submit" className="btn btn-block">
          login
        </button>
      </form>
    </section>
  )
}
