import { Questionnaire } from '../api/questionnaires/models'

export const ObjectQuestionnaireItem = ({
  questionnaire_item,
}: {
  questionnaire_item: Questionnaire
}) => {
  return (
    <div className="questionBox">
      <div className="qestionDescription"></div>
      <div className="question">{questionnaire_item.bodies.OBJECT?.body}</div>
    </div>
  )
}
