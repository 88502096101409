import axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { Assign } from 'utility-types'

const api = axios.create()

enum DOMAIN {
  LOCAL = 'http://localhost:8000/',
  DEVELOPMENT = 'https://api.evans24.pl/',
}

const apiDomain = process.env.REACT_APP_API as unknown as typeof DOMAIN
//@ts-ignore
api.defaults.baseURL = apiDomain ? DOMAIN[apiDomain] : DOMAIN.LOCAL

api.defaults.headers.common.Accept = 'application/json'
api.defaults.headers.post['Content-Type'] = 'application/json'

function getToken() {
  const value = localStorage.getItem('user')
  if (value) {
    //@ts-ignore
    return JSON.parse(value)?.access
  }
  return null
}

api.interceptors.request.use(async (config) => {
  const headers: Record<string, string> = {}
  const userToken = getToken()

  if (userToken) {
    headers.Authorization = `Bearer ${userToken}`
  }

  config.headers = {
    ...config.headers,
    ...headers,
  }

  return config
})

api.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  }
)

/**
 * This type overrides AxiosInstance methods responses to reflect response => response.data interceptor
 */
type AxiosInstanceInterceptedNonPayloadResponse = <T = unknown, D = unknown>(
  url: string,
  config?: AxiosRequestConfig<D>
) => Promise<T>

type AxiosInstanceInterceptedPayloadResponse = <T = unknown, D = unknown>(
  url: string,
  data: D | undefined,
  config?: AxiosRequestConfig<D>
) => Promise<T>

export default api as Assign<
  AxiosInstance,
  {
    delete: AxiosInstanceInterceptedNonPayloadResponse
    get: AxiosInstanceInterceptedNonPayloadResponse
    patch: AxiosInstanceInterceptedPayloadResponse
    post: AxiosInstanceInterceptedPayloadResponse
    put: AxiosInstanceInterceptedPayloadResponse
  }
>
