import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserInfo } from '../api/users/hooks'
import { useLocalStorage } from '../hooks/useLocalStorage'

export interface Auth {
  user: any
  login: (data: any) => void
  logout: () => void
  userInfo: any
}

const noop = () => {}
const AuthContext = createContext<Auth>({
  login: noop,
  logout: noop,
  user: {},
  userInfo: {},
})

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const { data, isLoading } = useUserInfo()
  const navigate = useNavigate()

  const login = (data: any) => {
    setUser(data)
    navigate('/studies')
  }

  // call this function to sign out logged in user
  const logout = () => {
    setUser(null)
    navigate('/', { replace: true })
  }

  const value = useMemo(
    () => ({
      user,
      userInfo: data,
      login,
      logout,
    }),
    [user, data]
  )
  return (
    <AuthContext.Provider value={value}>
      {isLoading ? 'Ładowanie...' : children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => {
  return useContext(AuthContext)
}
