import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { ReactQueryDevtools } from 'react-query/devtools'
import { QueryClientProvider, QueryClient } from 'react-query'

import { Login } from './components/Login'

import { ProtectedRoute } from './components/ProtectedRoute'
import { Studies } from './pages/studies/Studies'
import { Study } from './pages/study/Study'
import { AuthProvider } from './context/AuthProvider'
import { Manuals } from './pages/manuals/Manuals'
import { Questionnaire } from './pages/questionnaire/Questionnaire'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <AuthProvider>
          <div>
            <Routes>
              <Route
                path="/"
                element={
                  <ProtectedRoute>
                    <div>
                      <Link to="/studies">studies</Link>
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route path="/login" element={<Login />} />
              <Route
                path="/studies"
                element={
                  <ProtectedRoute>
                    <Studies />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/studies/:studyId"
                element={
                  <ProtectedRoute>
                    <Study />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/manuals/:id"
                element={
                  <ProtectedRoute>
                    <Manuals />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/questionnaire/:type/:id"
                element={
                  <ProtectedRoute>
                    <Questionnaire />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </div>
        </AuthProvider>
      </Router>

      <ReactQueryDevtools initialIsOpen={false} position={'bottom-right'} />
    </QueryClientProvider>
  )
}

export default App
