import { useMutation, useQuery } from 'react-query'
import { getManual, postFinishSection } from './calls'
import { manualsKeys } from './keys'
import { Manual } from './models'

// TODO: a co gdy będziemy mieli 10 stron ?
export const useManuals = (
  direction: 'current' | 'next' | 'previous',
  contentId?: string
) => {
  return useQuery<Manual>(
    manualsKeys.manual(),
    () => getManual(direction, contentId),
    {
      staleTime: 1 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    }
  )
}

export const useFinishMutation = () => {
  return useMutation((args: { content: string; relatedTo: string }) =>
    postFinishSection(args)
  )
}
