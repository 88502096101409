import { useParams } from 'react-router'
import {
  useQuestionnaireResultDeleteMutation,
  useQuestionnaireResultPatchMutation,
  useQuestionnaireResultPostMutation,
} from '../api/questionnaires/hooks'
import { Questionnaire } from '../api/questionnaires/models'
import { Direction } from '../api/sharedTypes'
import { useAuth } from '../context/AuthProvider'

export const OptionPanel = ({
  questionnaire_item,
  kind,
  fetchPage,
}: {
  questionnaire_item: Questionnaire
  kind: 'SUBJECT' | 'OBJECT'
  fetchPage: (direction: Direction) => void
}) => {
  const postMutation = useQuestionnaireResultPostMutation()
  const patchMutation = useQuestionnaireResultPatchMutation()
  const deleteMutation = useQuestionnaireResultDeleteMutation()

  const { userInfo } = useAuth()
  const { id } = useParams<{
    id: string
  }>()

  const toggle = (optionId: string) => {
    const baseProperties = {
      content: id!,
      attempt: questionnaire_item.attempt,
      kind,
      questionnaire_item: questionnaire_item.id,
      subject: userInfo.subject_id,
    }

    const selectedAnswer = questionnaire_item.selected_answers?.[kind]
    if (isChecked(optionId, questionnaire_item, kind)) {
      deleteMutation.mutate(selectedAnswer!.id, {
        onSuccess: () => {
          fetchPage('current')
        },
      })
      return
    }

    if (selectedAnswer) {
      patchMutation.mutate(
        {
          id: selectedAnswer.id,
          option: optionId,
        },
        {
          onSuccess: () => {
            fetchPage('current')
          },
        }
      )
      return
    }

    postMutation.mutate(
      {
        ...baseProperties,
        option: optionId,
      },
      {
        onSuccess: () => {
          fetchPage('current')
        },
      }
    )
  }

  return (
    <div className="answerOptionsBox">
      <div className="pb-2">
        <h5> {kind === 'SUBJECT' ? <div>Ty</div> : <div>Partner(ka)</div>}</h5>
        <h6>Zaznacz jedną odpowiedź</h6>
      </div>
      {questionnaire_item.answer.options.map((option) => {
        const checked = isChecked(option.id, questionnaire_item, kind)
        return (
          <label
            htmlFor={`${kind}-${option.id}`}
            className="optionBox"
            key={`${kind}-${option.id}`}
            onClick={() => toggle(option.id)}
          >
            <input type="radio" name={`${kind}-${option.id}`} />
            <span className={`option ${checked ? 'option-checked' : ''}`}>
              {option.label}
            </span>
          </label>
        )
      })}
    </div>
  )
}

function isChecked(
  optionId: string,
  questionnaire_item: Questionnaire,
  kind: 'SUBJECT' | 'OBJECT'
) {
  if (!questionnaire_item.selected_answers) return false
  if (questionnaire_item.selected_answers[kind]?.option === optionId)
    return true
}
