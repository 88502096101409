import { useNavigate, useParams } from 'react-router-dom'
import { useFinishMutation } from '../../api/manuals/hooks'
import arrowSlimRight from '../../assets/images/arrows_slim_right.svg'
import arrowSlimLeft from '../../assets/images/arrows_slim_left.svg'
import i1 from '../../assets/images/i1.svg'
import { useEffect, useState } from 'react'
import { SubjectQuestionnaireItem } from '../../components/SubjectQuestionnaireItemPanel'
import { ObjectQuestionnaireItem } from '../../components/ObjectQuestionnaireItemPanel'
import { OptionPanel } from '../../components/OptionPanel'
import { Direction, QuestionnaireType, RelatedTo } from '../../api/sharedTypes'
import { useQuestionnaire } from '../../api/questionnaires/hooks'
import { useSetStudiesAsFilledMutation } from '../../api/studies/hooks'
import { Break } from '../break/Break'

export const Questionnaire = () => {
  const [direction, setDirection] = useState<'current' | 'next' | 'previous'>(
    'current'
  )
  const [breakPage, setBreakPage] = useState<{
    id: string
    available_at: string
  } | null>(null)

  //TODO Proszę usuń mnie. Potrzebny refactoring
  const [forceRerender, setForceRerender] = useState(0)

  const mutation = useFinishMutation()
  const setStudiesAsFilledMutation = useSetStudiesAsFilledMutation()

  const { id, type } = useParams<{
    id: string
    type: QuestionnaireType
  }>()

  const navigate = useNavigate()

  const { data, isLoading, refetch, isFetching } = useQuestionnaire(
    type!,
    direction,
    id!
  )

  useEffect(() => {
    refetch()
  }, [forceRerender])

  if (!data) return null

  const next = () => {
    if (isFetching) return
    if (data.has_next && !isLoading) {
      fetchPage('next')
      return
    }

    if (!data.has_next && type === 'study') {
      setStudiesAsFilledMutation.mutate(id!, {
        onSuccess: (payload) => {
          if (!payload.next_item_data) {
            navigate('/studies')
            return
          }

          setBreakPage({
            id: payload.next_item_data.id,
            available_at: payload.next_item_data.available_at,
          })
        },
      })
      return
    }

    mutation.mutate(
      {
        content: id!,
        relatedTo: type === 'study' ? 'STUDY_SET' : 'TRAINING_SET',
      },
      {
        onSuccess: (payload) => {
          fetchPage('current')

          navigate(
            `/questionnaire/${
              questionnaireTypeDict[payload.next_related_to]
            }/${id}`
          )
        },
      }
    )
  }

  const prev = () => {
    if (data.has_previous && !isLoading) {
      fetchPage('previous')
      return
    }
  }

  const fetchPage = (direction: Direction) => {
    setDirection(direction)
    setForceRerender(Math.random())
  }

  const nextLabel = (() => {
    if (type === 'study') {
      return 'Zakończ badanie'
    }
    if (type === 'trial') {
      return 'Przejdź do badania'
    }
  })()

  if (breakPage) {
    return <Break available_at={breakPage.available_at} id={breakPage.id} />
  }

  return (
    <div className="testPageGrid">
      <div className="testLabel">
        {type === 'study' ? 'Badanie' : 'Trening'}
      </div>
      <div className="testContent">
        <div className="testLeftSide">
          <SubjectQuestionnaireItem questionnaire_item={data} />
          <OptionPanel
            questionnaire_item={data}
            kind="SUBJECT"
            fetchPage={fetchPage}
          />
        </div>
        <div className="testRightSide">
          <ObjectQuestionnaireItem questionnaire_item={data} />
          <OptionPanel
            fetchPage={fetchPage}
            questionnaire_item={data}
            kind="OBJECT"
          />
        </div>
      </div>
      <div className="testNavBar">
        <div className="progressBarBox">
          <div className="progressBarBg">
            <div className="progressBarInverted" style={{ width: '10%' }}></div>
          </div>
          <div className="progressCounter">00:06</div>
        </div>

        <div className="navContent">
          <div className="d-flex align-items-center">
            <button className="btn iconBtn me-3">
              <img src={i1} alt="" />
            </button>
            <button
              className="btn iconBtn btnSecondary btnMedium me-3"
              onClick={prev}
            >
              <img src={arrowSlimLeft} alt="" />
            </button>
            <div className="questionCounter d-flex align-items-end ps-3">
              <div className="pe-3">
                <div className="label">pytanie</div>
                <div>
                  <strong>{data.number}</strong>
                  <span>/{data.number_of_all_items}</span>
                </div>
              </div>
              <div>
                <strong>{data.progress}%</strong>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="testName">
              {type === 'study' ? `${data.code}` : 'Spróbuj sam'}
              </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btnPrimary btnContentRight btnLarge"
              onClick={next}
              disabled={isFetching}
            >
              {isFetching || isLoading ? (
                'ładowanie'
              ) : (
                <span className="pe-3">
                  {data.has_next ? 'Dalej' : nextLabel}
                </span>
              )}

              <img src={arrowSlimRight} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

const questionnaireTypeDict: Record<RelatedTo, QuestionnaireType> = {
  MANUAL: 'study',
  STUDY_SET: 'study',
  TRAINING_SET: 'trial',
}
