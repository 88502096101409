import api from '../connector'
import { Direction, RelatedTo } from '../sharedTypes'
import { Manual } from './models'

export const getManual = (
  direction: Direction,
  contentId?: string // content będzie zawsze, może lepiej nazwać to contentId
) => api.get<Manual>(`/manuals/${direction}/content=${contentId}`)

export const postFinishSection = async ({
  content,
  relatedTo,
}: {
  content: string
  relatedTo: string
}) =>
  await api.get<{ next_related_to: RelatedTo }>(
    `/subject-contents/finish-section/content=${content}/related-to=${relatedTo}`
  )
