import { useMutation, useQuery } from 'react-query'
import { Direction, QuestionnaireType } from '../sharedTypes'
import {
  deleteQuestionnaireResult,
  getQuestionnaire,
  patchQuestionnaireResult,
  postQuestionnaireResult,
} from './calls'
import { questionnaireKeys } from './keys'
import { PostResultRequestData, Questionnaire } from './models'

export const useQuestionnaire = (
  type: QuestionnaireType,
  direction: Direction,
  contentId: string
) => {
  return useQuery<Questionnaire>(
    questionnaireKeys.questionnaire(),
    () => getQuestionnaire(type, direction, contentId),
    {
      staleTime: 1 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    }
  )
}

export const useQuestionnaireResultPostMutation = () => {
  return useMutation(postQuestionnaireResult)
}

export const useQuestionnaireResultPatchMutation = () => {
  return useMutation(patchQuestionnaireResult)
}
export const useQuestionnaireResultDeleteMutation = () => {
  return useMutation(deleteQuestionnaireResult)
}
