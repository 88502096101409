import api from '../connector'
import { Direction, QuestionnaireType } from '../sharedTypes'
import { PostResultRequestData, Questionnaire } from './models'

export const getQuestionnaire = (
  type: QuestionnaireType,
  direction: Direction,
  contentId: string
) =>
  api.get<Questionnaire>(
    `/questionnaires-items/${type}/${direction}/content=${contentId}`
  )

export const postQuestionnaireResult = async (data: PostResultRequestData) =>
  await api.post<{
    id: string
    attempt: number
    label: string
    value: number
    content: string
    questionnaire_item: string
    subject: string
    option: string
    quest_item_status: string
    kind: string
    created_at: string
    updated_at: string
    updated_by: string
  }>(`/questionnaires/results/items`, data)

export const patchQuestionnaireResult = async (data: {
  id: string
  option: string
}) =>
  await api.patch<'ok'>(`/questionnaires/results/items/${data.id}`, {
    option: data.option,
  })

export const deleteQuestionnaireResult = async (id: string) =>
  await api.delete<'ok'>(`/questionnaires/results/items/${id}`)
