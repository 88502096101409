import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { RelatedTo } from '../../api/sharedTypes'
import { useSingleStudyForSubject } from '../../api/studies/hooks'
import { SingleStudy } from '../../api/studies/models'

export const Study = () => {
  const { studyId } = useParams<{ studyId: string }>()

  const { data, isLoading } = useSingleStudyForSubject(studyId!)

  if (isLoading || !data) {
    return <h2>loading ...</h2>
  }
  return (
    <div>
      {data.map((content) => (
        <div key={content.id}>
          {isAvailable(content) ? (
            <Link to={`/${getDirection(content)}/${content.id}`}>
              {content.name}
            </Link>
          ) : (
            <div>
              {content.name}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

function isAvailable(content: SingleStudy): boolean {
  if (content.status === 'FILLED') return false
  if (!content.available_at) return false
  return new Date() > new Date(content.available_at);
}

function getDirection(content: SingleStudy) {
  const directionDict: Record<RelatedTo, string> = {
    MANUAL: 'manuals',
    STUDY_SET: 'questionnaire/study',
    TRAINING_SET: 'questionnaire/trial',
  }
  const sectionKind = content.current_section?.related_to
  if (sectionKind) {
    return directionDict[sectionKind]
  }
  // TODO: czy jeśli nie mamy current_section to chcemy przekierować użytownika na 'manual'?
  return 'manuals'
}
