import { Link } from 'react-router-dom'
import { useStudiesForSubject } from '../../api/studies/hooks'
import { useAuth } from '../../context/AuthProvider'

export const Studies = () => {
  const { logout } = useAuth()
  const { data, isLoading, isFetching } = useStudiesForSubject()

  if (!data) {
    return <div>Loading ...</div>
  }

  return (
    <div>
      <button onClick={logout}>Logout</button>
      {data.results.map(({ id, name }) => (
        <div>
          <Link to={id}>{name}</Link>
        </div>
      ))}
    </div>
  )
}
