import { useNavigate, useParams } from 'react-router-dom'
import { useFinishMutation, useManuals } from '../../api/manuals/hooks'
import arrowSlimRight from '../../assets/images/arrows_slim_right.svg'
import arrowSlimLeft from '../../assets/images/arrows_slim_left.svg'
import i1 from '../../assets/images/i1.svg'
import { useEffect, useState } from 'react'
import { QuestionnaireType, RelatedTo } from '../../api/sharedTypes'

export const Manuals = () => {
  const [direction, setDirection] = useState<'current' | 'next' | 'previous'>(
    'current'
  )

  //TODO Proszę usuń mnie. Potrzebny refactoring
  const [forceRerender, setForceRerender] = useState(0)

  const { id } = useParams<{
    id: string
  }>()

  const mutation = useFinishMutation()
  const navigate = useNavigate()

  const { data, isLoading, refetch } = useManuals(direction, id)

  useEffect(() => {
    refetch()
  }, [forceRerender])

  if (!data) return null

  const next = () => {
    if (data.has_next && !isLoading) {
      setDirection('next')
      setForceRerender(Math.random())
      return
    }
    mutation.mutate(
      { content: id!, relatedTo: 'MANUAL' },
      {
        onSuccess: (payload) => {
          const questionnaireTypeDict: Record<RelatedTo, QuestionnaireType> = {
            MANUAL: 'study',
            STUDY_SET: 'study',
            TRAINING_SET: 'trial',
          }
          navigate(
            `/questionnaire/${
              //@ts-ignore
              questionnaireTypeDict[payload.next_related_to]
            }/${id}`
          )
        },
      }
    )
  }

  const prev = () => {
    if (data.has_previous && !isLoading) {
      setDirection('previous')
      setForceRerender(Math.random())
      return
    }
  }

  const kindDict = {
    TEXT: (
      <div className="row testContent">
        <div className="introTextBox">
          <p>{data.title}</p>
          <h2>{data.title}</h2>
          <p>{data.content}</p>
          <h4>{data.position}</h4>
        </div>
      </div>
    ),
    VIDEO: (
      <div className="row testContent">
        <div className="videoBox">
          <video controls autoPlay={true}>
            <source src={data.url!} type="video/mp4" />
          </video>
        </div>
      </div>
    ),
  }

  return (
    <div className="testPageGrid">
      <div className="inrtoLabel">Instrukcja</div>

      {kindDict[data.kind]}

      <div className="testNavBar">
        <div className="progressBarBox"></div>
        <div className="navContent">
          <div className="d-flex align-items-center">
            <button
              className="btn iconBtn me-3"
              disabled={data.has_previous || isLoading}
            >
              <img src={i1} alt="" />
            </button>
            <button
              className="btn iconBtn btnSecondary btnMedium me-3"
              onClick={prev}
            >
              <img src={arrowSlimLeft} alt="" />
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <div className="testName">
              Instrukcja jak wypełniać kwestionariusz
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <button
              className="btn btnPrimary btnContentRight btnLarge"
              onClick={next}
              disabled={isLoading}
            >
              <span className="pe-3">
                {data.has_next ? 'Przejdź dalej' : 'Rozpocznij trening'}
              </span>
              <img src={arrowSlimRight} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
