import api from '../connector'
import { PaginatedDTOWrapper } from '../subjects/dto'
import { SingleStudy, Study } from './models'

export const getStudiesForSubject = () => {
  return api.get<PaginatedDTOWrapper<Study>>(`studies/items`)
}

export const getSingleStudyForSubject = (id: string) => {
  return api.get<SingleStudy[]>(`studies/study=${id}`)
}

export const getSetStudiesAsFilled = (content: string) => {
  return api.get<{
    status: string
    finished_at: string
    next_item_data: {
      id: string
      kind: string
      name: string
      number_of_questions: number
      available_at: string
      average_time: number | null
    } | null
    can_start_before_pause_time_passes: boolean
    pause_time: {
      days: number
      hours: number
      minutes: number
    }
  }>(`studies/set-as-filled/content=${content}`)
}
